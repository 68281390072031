import Compressor from "compressorjs";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { ImCross } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  Call_Get_Profile_Image,
  Call_Update_Profile_Image,
  state_Customer_Profile_Image,
  state_Is_Loading,
} from "../../../redux/Profile/CustomerProfileImageSlice";
import ChiptDataLoader from "../../NavigationComponents/Chipt_Data_Loader";

const Chipt_Profile_Account_Image_Update = () => {
  const dispatch = useDispatch();
  const customerProfileImage = useSelector(state_Customer_Profile_Image);
  const isLoading = useSelector(state_Is_Loading);

  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState();
  const [uploadImage, setUploadImage] = useState(null);
  const [tempProfile, setTempProfile] = useState(
    "http://res.cloudinary.com/dva7hs5oo/image/upload/v1697284398/default/rnbfoapzh3yn5pg8mknh.png"
  );

  const ProfileEditButtonEnable = (e) => {
    e.preventDefault();
    if (editProfile) setUploadImage(null);
    setEditProfile(!editProfile);
  };

  const handlepreviewProfile = async (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      const compressedBlob = await new Promise((resolve, reject) => {
        new Compressor(e.target.files[0], {
          quality: 0.65,
          maxWidth: 800,
          maxHeight: 800,
          mimeType: "image/jpeg",
          success(result) {
            resolve(result);
          },
          error(error) {
            reject(error);
          },
        });
      });
      const formData = new FormData();
      formData.append("file", compressedBlob);
      setUploadImage(formData);
      setTempProfile(URL.createObjectURL(compressedBlob));
    }
  };

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    if (uploadImage) {
      dispatch(Call_Update_Profile_Image(uploadImage)).then((data) => {
        if (data.payload) {
          if (data.payload.data.success) {
            setLoading(false);
            setUploadImage(null);
            setEditProfile(false);
            setCustomerData(data.payload.data.customer);
            toast.success(`Profile picture updated successfully!`, {
              duration: 2500,
            });
          } else {
            setLoading(false);
            toast.error(data.payload.data.message, { duration: 2500 });
          }
        }
      });
    } else {
      toast.error("Please select an image.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerProfileImage) {
      if (customerProfileImage.data) {
        setCustomerData(customerProfileImage.data.customer);
      } else setCustomerData(customerProfileImage.customer);
    } else if (!customerProfileImage) {
      dispatch(Call_Get_Profile_Image());
    }
  }, [customerProfileImage, dispatch]);

  const renderProfileImage = (imageUrl) => (
    <img
      className="w-20 h-20 rounded-full"
      src={imageUrl}
      alt="profile_image"
    />
  );

  const imageUrl = !uploadImage
    ? customerData?.profilePicture?.url || tempProfile
    : tempProfile;

  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-center items-center">
        <div className="w-20 h-20 overflow-hidden relative">
          {isLoading || loading
            ? renderProfileImage(imageUrl)
            : renderProfileImage(imageUrl)}
          <div className="w-6 absolute bottom-0 right-0 z-50 h-6 rounded-full p-1 bg-primary text-white flex items-center justify-center">
            {editProfile ? (
              <p className="text-sm text-red hover:text-red-hover cursor-pointer">
                <ImCross onClick={ProfileEditButtonEnable} />
              </p>
            ) : (
              <p className="text-sm text-white cursor-pointer hover:text-primaryBlue-hover">
                <MdEdit onClick={ProfileEditButtonEnable} />
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        {loading ? (
          <div className="w-full h-auto flex items-center justify-start">
            <span className="w-1/3 text-xs font-bold text-navy">Uploading</span>
            <span>
              <ChiptDataLoader className="w-2/3" />
            </span>
          </div>
        ) : (
          editProfile && (
            <div className="w-full mt-5 flex justify-between items-center">
              <div className="w-full h-auto my-1 flex items-end justify-start">
                <input
                  id="imageUpload"
                  className="text-[0.75rem] h-auto"
                  type="file"
                  name="myImage"
                  accept="image/png, image/jpeg"
                  placeholder="Upload"
                  onChange={handlepreviewProfile}
                  autoComplete="off"
                />
              </div>
              <div className="text-xl flex items-center justify-start">
                <button
                  className="text-sm bg-primary hover:bg-primary text-text-light font-bold py-1 px-2 my-1 rounded-sm"
                  onClick={handleProfileUpdate}
                >
                  Save
                </button>
              </div>
            </div>
          )
        )}
      </div>
      <Helmet>
        <title>CHIPT | PROFILE UPDATE</title>
      </Helmet>
    </div>
  );
};

export default Chipt_Profile_Account_Image_Update;
