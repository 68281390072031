import React from "react";

const Loader = () => {
  return (
    <div className="text-center">
      <div className="loader mx-auto "></div>
      <span className=" text-black text-xs font-normal mx-auto   mt-[14px]">Loading...</span>
    </div>
  );
};

export default Loader;
