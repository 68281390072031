import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const Call_New_Order_Creation = createAsyncThunk(
  "customerNewOrder/Call_New_Order_Creation",
  async (data) => {
    const response = await axios.post("/api/v1/customer/order/new/order", data);
    await axios.post("/api/v1/customer/order/add/logs", {
      action: JSON.stringify(data),
    });
    return response.data;
  }
);

const customerNewOrderSlice = createSlice({
  name: "customerNewOrder",
  initialState: {
    status: "idle",
    isLoading: false,
    order: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Scanned Cup Details
      .addCase(Call_New_Order_Creation.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.order = null;
        state.error = null;
      })
      .addCase(Call_New_Order_Creation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.order = action.payload;
        state.error = null;
      })
      .addCase(Call_New_Order_Creation.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.order = false;
        state.error = action.error.message;
      });
  },
});

export const state_Call_Fetch_Status = (state) => state.customerNewOrder.status;
export const state_Is_Loading = (state) => state.customerNewOrder.isLoading;
export const state_Customer_New_Order = (state) => state.customerNewOrder.order;
export const state_Has_Error = (state) => state.customerNewOrder.error;

export default customerNewOrderSlice.reducer;
