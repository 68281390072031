import React from 'react'
import { NavLink } from "react-router-dom";


const Qr = () => {
  return (
    
         <div className=" h-full px-8 flex flex-col justify-center items-center">
      <p className=" max-w-[272px] mx-auto text-center text-[#000000] font-medium text-base leading-5">
        tap the icon below to SCAN YOUR CUP
      </p>
      <NavLink to={"/qr/reader"}>
      <img src="/assets/svg/nfc/nfccup.svg" className=" mt-12 w-[264px] h-[267px]" alt="" />

      </NavLink>
    </div>
      
  )
}

export default Qr
