import React from "react";
import { RiHandCoinLine } from "react-icons/ri";
import store_Cup from "../asset/storeCup.svg";

const Chipt_Show_Vendor_Store = ({ storeData }) => {
  return (
    <>
      <div
        key={storeData.cupID}
        className="w-[46%] mx-[2%] h-[9rem] my-3 rounded-lg shadow-md hover:shadow-xl bg-white shadow-card-shadow-ligh-dark overflow-hidden"
      >
        <div className="p-2 overflow-hidden">
          <div className="w-[100%] h-[5rem] float-left flex items-center justify-center">
            <img
              className="w-auto h-[5rem] "
              src={store_Cup}
              alt="vendor_store"
            />
          </div>

          <div className="w-[100%] h-[3rem] float-left">
            <div className="h-[1.5rem] flex items-center justify-center">
              <p className="w-[50%] text-xs font-medium px-2 float-lefttext-left">
                {storeData.cupType.charAt(0).toUpperCase() +
                  storeData.cupType.slice(1)}
              </p>
              <p className="w-[50%] text-xs font-medium px-2 float-left flex items-center justify-end">
                <RiHandCoinLine className="text-sm text-gold" />
                &nbsp;{storeData.points}
              </p>
            </div>
            <div className="h-[1.5rem] flex items-center justify-center text-xs font-normal bg-otp-border-color rounded-xl">
              <p className="w-[50%] float-left px-2 text-xs text-left font-medium">
                Credits
              </p>
              <p className="w-[50%] float-left px-2 text-xs text-right font-bold">
                {storeData.cupPrice}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chipt_Show_Vendor_Store;
