import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import WelcomeLogo from "../asset/window1.svg";
// import ChiptLogo from "../asset/svg/logo/chipt logo.svg";
// import { state_Is_Authenticated } from "../../redux/authentication/CustomerAuthSlice";
// import Chipt_Security_Footer from "../NavigationComponents/Chipt_Security_Footer";
import { Helmet } from "react-helmet";
import Loader from "../loader/Loader";
import WelcomeSlider from "../slider/WelcomeSlider";

const Chipt_Welcome = () => {
  const [loading, setLoading] = useState(true);
  const [showLayout, setShowLayout] = useState(false);
  // const navigate = useNavigate();
  // const isAuthenticated = useSelector(state_Is_Authenticated);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setShowLayout(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/home");
  //   }
  // }, [isAuthenticated]);
  return (
    <>
      {!showLayout ? (
        <div className="w-[100%] h-screen relative flex overflow-hidden justify-center p-2 items-center flex-col">
          <div className=" w-[200px] mx-auto h-[200px] flex justify-center items-centersignin">
            <img
              className="w-auto  h-full object-cover cursor-pointer"
              src={"/assets/images/logo/Chiptlogoblack.png"}
              alt="Chipt_Logo"
            />
          </div>

          <div className=" absolute bottom-20 left-1/2 transform -translate-x-1/2">
            {loading && <Loader />}
          </div>
          <div className="w-[100%] absolute bottom-2 left-0 right-0  h-auto flex items-center justify-center overflow-hidden">
            {/* <NFCScanBar /> */}
            <div className=" text-center font-medium pb-5  text-xs">
              2024 - Chipt © Daly Trading Sdn Bhd
            </div>
          </div>
          {/* <div className="w-[90%] h-auto mx-[5%] float-left">
          <div className='w-[100%] h-[3rem] mx-0 float-start flex items-center justify-end'>
            <Link to="/about"><button className='float-right bg-gray-light rounded-xl text-xs font-bold px-2 py-1' >NEXT</button></Link>
          </div>

          <div
            className="w-full h-full mx-0"
            onClick={() => navigate("/about")}
          >
            <div className="h-[14rem] flex items-center justify-start">
              <img
                className="w-auto h-[5rem] mt-[2rem]"
                src={WelcomeLogo}
                alt="Chipt_Logo"
              />
            </div>
          </div>
        </div> */}

          {/* <div className='w-[80%] h-[14rem] mx-[10%] float-left text-center'>
          <div className='h-[1.5rem] mb-[8rem] flex items-start justify-center'>
            <Link to="/signin"><p className='text-md font-bold underline decoration-1 break-all'>Already have an account?</p></Link>
          </div>
        
        </div> */}
        </div>
      ) : (
        <WelcomeSlider />
      )}

      <Helmet>
        <title>CHIPT | WELCOME</title>
      </Helmet>
    </>
  );
};

export default Chipt_Welcome;
