import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { RiHandCoinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useToggle, useVibrate } from "react-use";
import { ActionsContext } from "../../context/Context";
import { state_Vendors } from "../../redux/authentication/AllVendorSlice";
import { state_Is_Authenticated } from "../../redux/authentication/CustomerAuthSlice";
import { state_Has_Error } from "../../redux/orders/NewOrderCreation";
import {
  Call_Scanned_Cup_Details,
  removeVendorId,
  state_Cup_Details,
  state_Is_Loading,
  state_Vendor_Id,
} from "../../redux/orders/ScannedCupSlice";
import CupDetails from "../asset/cupDetails.svg";

const Chipt_Cup_Order_Preview = () => {
  const navigate = useNavigate();
  // const profile = useSelector(state_Customer_Profile);
  const { mid, cid } = useParams();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state_Is_Authenticated);
  const isLoading = useSelector(state_Is_Loading);
  const state_cupDetail = useSelector(state_Cup_Details);
  const stateVendors = useSelector(state_Vendors);
  const hasError = useSelector(state_Has_Error);

  const [cupDetails, setCupDetails] = useState(state_cupDetail?.cupData);
  const [vendors, setVendors] = useState();
  const vendorIdFromState = useSelector(state_Vendor_Id);
  const [vendorID, setVendorID] = useState(vendorIdFromState || "");
  const [selectedVendor, setSelectedVendor] = useState();

  const [inputPoint, setInputPoint] = useState();
  const [points, setPoints] = useState(0);
  const [loyalityPoint, setLoyalityPoint] = useState(0);
  const [canOrder, setCanOrder] = useState(true);

  const screenContext = useContext(ActionsContext);
  const [screenWidth, setScreenWidth] = useState(
    screenContext.screenSize.current.width
  );

  // const [customer, setCustomer] = useState(null);
  // const [wallet, setWallet] = useState(null);
  // const [walletLoading, setWalletLoading] = useState(false);

  // useEffect(() => {
  //   setWalletLoading(true);
  //   dispatch(Call_Get_Profile()).then((data) => {
  //     if (data.payload) {
  //       setCustomer(data.payload.customer);
  //       fetchWalletData(data.payload.customer._id);
  //     }
  //   });
  // }, [dispatch]);

  // const fetchWalletData = async (id) => {
  //   try {
  //     setWalletLoading(true);
  //     const response = await axios.get(`/api/v1/customer/wallet/${id}`);

  //     console.log(response.data);
  //     if (response.data && response.data.data) {
  //       setWallet(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching wallet data:", error);
  //   } finally {
  //     setWalletLoading(false);
  //   }
  // };

  // Vibration
  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [10], false);

  // const handleLoyaltyInput = (e) => {
  //     e.preventDefault();
  //     if (e.target.value > loyalityPoint) {
  //         toast.error(`Maximum ${loyalityPoint} points can be used for this order.`)
  //     } else {
  //         setInputPoint(e.target.value);
  //         setPoints(0);
  //     }
  // }

  // const handleRedeemPoints = (e) => {
  //     e.preventDefault();
  //     setPoints(inputPoint);
  //     toast.success(`Points applied.`)
  // }

  const handleVendorChange = (e) => {
    e.preventDefault();
    for (let i = 0; i < vendors.length; i++) {
      if (vendors[i]._id.toString() === e.target.value.toString()) {
        setSelectedVendor(vendors[i]);
        return;
      } else {
        setSelectedVendor(null);
      }
    }
    setVendorID(e.target.value);
  };

  // New Order Placement

  const sendOrder = async (data) => {
    try {
      const response = await axios.post(
        "/api/v1/customer/order/new/order",
        data
      );
      await axios.post("/api/v1/customer/order/add/logs", {
        action: JSON.stringify(data),
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  const handleProceedNowOrder = async (e) => {
    e.preventDefault();
    dispatch(removeVendorId());
    const tempData = {
      modelId: mid.toUpperCase(),
      uniqueId: cid.toUpperCase(),
      vendor: selectedVendor._id,
      amount: cupDetails.price,
      currency: cupDetails.currency.toUpperCase(),
    };

    if (!tempData.modelId || !tempData.uniqueId) {
      toast.error(`Something went wrong, Please scan cup again!`);
      return;
    }
    if (!tempData.vendor) {
      toast.warning("Please select a vendor!");
      return;
    }

    if (tempData.modelId && tempData.uniqueId && tempData.vendor) {
      if (screenWidth < 720) {
        setTimeout(() => {
          toggleVibrating();
        }, 0);
        setTimeout(() => {
          toggleVibrating();
          // dispatch(Call_New_Order_Creation(tempData))
          sendOrder(tempData)
            .then((data) => {
              debugger;
              console.log(data);
              if (
                data?.message ===
                "Order can't be placed as this vendor don't have the cup stock"
              ) {
                toast.error(
                  "Order can't be placed as this vendor don't have the cup stock"
                );
              } else {
                toast.success("Cup purchased");

                window.location.replace("/wallet");
              }
            })
            .catch((err) => {
              toast.error(`Error: ${err?.response?.data?.message}`);
            });
          // navigate("/order/payment");
          // navigate("/wallet");
        }, 100);
      } else {
        // dispatch(Call_New_Order_Creation(tempData))
        sendOrder(tempData)
          .then((data) => {
            debugger;
            console.log(data);
            if (
              data?.message ===
              "Order can't be placed as this vendor don't have the cup stock"
            ) {
              toast.error(
                "Order can't be placed as this vendor don't have the cup stock"
              );
            } else {
              toast.success("Cup purchased");
              window.location.replace("/wallet");
            }
          })
          .catch((err) => {
            toast.error(`Error: ${err?.response?.data?.message}`);
          });
        // navigate("/order/payment");
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      toast.success(`Please login first`);
      navigate("/signin");
    }
  }, []);

  useEffect(() => {
    dispatch(Call_Scanned_Cup_Details({ modelID: mid, uniqueID: cid })).then(
      (data) => {
        console.log("Data in here to set", data);
        if (data?.payload?.cupData) {
          setCupDetails(data?.payload?.cupData);
        }
      }
    );
  }, []);

  useEffect(() => {
    setScreenWidth(screenContext.screenSize.current.width);
    // if (!state_cupDetail && !cupDetails) {
    //   dispatch(Call_Scanned_Cup_Details({ modelID: mid, uniqueID: cid }));
    // } else if (state_cupDetail && !cupDetails) {
    //   setCupDetails(state_cupDetail.cupData);
    // }
    setVendors(stateVendors.vendors);
  }, [isLoading, dispatch, selectedVendor]);

  useEffect(() => {
    if (cupDetails) {
      setCanOrder(cupDetails.isOrderable);
      if (!cupDetails.isOrderable) {
        setSelectedVendor(cupDetails.vendor);
      }
    }
  }, [canOrder, selectedVendor]);

  useEffect(() => {
    if (vendorIdFromState && vendors) {
      const vendor = vendors.find((v) => v._id === vendorIdFromState);
      if (vendor) {
        setSelectedVendor(vendor);
      }
    }
  }, [vendorIdFromState, vendors]);

  return (
    <>
      <div className="w-[100%] lg:w-[90%] lg:mx-[5%] min-h-screen h-auto float-left overflow-auto">
        <div className="w-[100%] h-[5vh] flex items-center justify-center">
          <p className="text-2xl font-bold">Order Preview</p>
        </div>

        {/* Cup Details */}
        <div className="w-[98%] min-h-[18rem] h-auto mx-[1%] my-4 bg-white float-left border border-gray-light rounded-lg shadow overflow-auto">
          {/* Cup */}
          <div className="w-[100%] min-h-[8rem] h-auto float-left overflow-auto my-6">
            <div className="w-[40%] min-h-[8rem] h-auto float-left">
              <div className="w-[100%] min-h-[7rem] h-auto float-left flex items-center justify-center">
                <img
                  className="w-[7rem] h-[7rem] rounded-sm"
                  src={
                    cupDetails?.cupImages[0]
                      ? cupDetails?.cupImages[0].url
                      : CupDetails
                  }
                  alt="cup_image"
                />
              </div>
            </div>

            <div className="w-[60%] min-h-[7rem] h-auto float-left px-2">
              <div className="w-[100%] h-auto font-bold flex flex-wrap items-center justify-start">
                <p className="w-[100%] text-3xl truncate overflow-hidden">
                  {cupDetails?.cupType && !isLoading ? cupDetails.cupType : ""}
                </p>
                <hr className="w-[70%] h-[2px] bg-navy text-navy rounded-full" />
              </div>

              <div className="w-[100%] h-auto font-bold flex items-end justify-start my-1">
                <p className="w-[100%] text-md truncate overflow-hidden">
                  {cupDetails?.cupSize ? cupDetails.cupSize : ""}&nbsp;
                  <i className="font-medium">
                    &#10098;
                    {cupDetails?.capacity ? cupDetails.capacity + " ml" : ""}
                    &#10099;
                  </i>
                </p>
              </div>

              <div className="w-[100%] font-bold flex items-end justify-start">
                <div className="w-[100%] flex items-center justify-start overflow-hidden">
                  <span className="text-sm">
                    {/* {cupDetails?.currency
                      ? cupDetails.currency.toUpperCase()
                      : ""} */}
                    {cupDetails?.price ? cupDetails.price : ""}
                  </span>
                  <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  <span className="text-sm flex items-center justify-center">
                    <RiHandCoinLine className="text-md text-gold" />
                    &nbsp;{cupDetails?.point ? cupDetails.point : 0}
                  </span>
                </div>
              </div>

              <div className="w-[100] text-[0.65rem] flex items-end justify-start">
                <p className="w-[100%] float-left overflow-hidden">
                  <i className="w-[25%] float-left">Model ID:</i>
                  <span className="w-[75%] float-left truncate overflow-hidden">
                    {mid}
                  </span>
                </p>
              </div>

              <div className="w-[100] text-[0.65rem] flex items-end justify-start">
                <p className="w-[100%] float-left overflow-hidden">
                  <i className="w-[25%] float-left">Unique ID:</i>
                  <span className="w-[75%] float-left truncate overflow-hidden">
                    {cid}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* Separator */}
          <hr className="w-[88%] mx-[6%] h bg-black my-4" />

          {/* Vendor Details */}

          <div className="w-[100%] min-h-[8rem] h-auto float-left overflow-auto my-4">
            {selectedVendor ? (
              <>
                <div className="w-[40%] min-h-[8rem] h-auto float-left">
                  <div className="w-[100%] min-h-[7rem] h-auto float-left flex items-center justify-center">
                    <img
                      className="w-[7rem] h-[7rem] rounded-sm"
                      src={selectedVendor.profilePicture.url}
                      alt="vendor_image"
                    />
                  </div>
                </div>

                <div className="w-[60%] min-h-[7rem] h-auto float-left px-2">
                  <div className="w-[100%] h-[2rem] font-bold flex flex-wrap items-center justify-start">
                    <p className="w-[100%] text-3xl truncate overflow-hidden">
                      {selectedVendor.name.charAt(0).toUpperCase() +
                        selectedVendor.name.slice(1)}
                    </p>
                    <hr className="w-[70%] h-[2px] bg-navy text-navy rounded-full" />
                  </div>
                  {selectedVendor.distance && (
                    <div className="w-[100%] h-auto flex items-end justify-start mt-4">
                      <p className="w-[100%] h-auto text-xs font-semibold break-words overflow-hidden">
                        {selectedVendor.distance} away from you.
                      </p>
                    </div>
                  )}
                  <div className="w-[100%] h-auto flex items-end justify-start mt-4">
                    <p className="w-[100%] h-[5rem]  text-xs break-words overflow-hidden">
                      {selectedVendor.plotnumber +
                        " " +
                        selectedVendor.address +
                        " " +
                        selectedVendor.city +
                        " " +
                        selectedVendor.state +
                        ", " +
                        selectedVendor.country +
                        " " +
                        selectedVendor.zipCode}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-[100%] min-h-[8rem] h-auto flex items-center justify-center">
                No Vendor selected
              </div>
            )}
          </div>
        </div>
        {/* Checking if orderable */}
        {canOrder ? (
          <>
            {/* Select Vendor */}
            <div className="w-[98%] h-[2.5rem] mx-[1%] bg-white flex items-center justify-center border border-gray-light rounded-2xl overflow-hidden">
              <div className="w-[100%] h-[2.5rem] flex items-center justify-center overflow-hidden">
                <p className="w-[40%] h-full text-sm font-medium flex items-center justify-start bg-gray-light px-4">
                  Select vendor
                </p>
                <select
                  value={vendorID}
                  onChange={handleVendorChange}
                  className="w-[60%] h-[2.5rem] rounded-lg bg-white text-sm px-2 outline-none appearance-none"
                  placeholder=""
                  disabled={!!vendorIdFromState}
                >
                  <option className="text-gray">
                    Please choose one option
                  </option>
                  {vendors ? (
                    <>
                      {vendors.map((data) => {
                        let isDistance = data.distance ? true : false;
                        return (
                          <option key={data._id} value={data._id}>
                            {isDistance ? (
                              <>
                                {data.name.charAt(0).toUpperCase() +
                                  data.name.slice(1)}{" "}
                                {"   (" + data.distance + " away)"}
                              </>
                            ) : (
                              <>
                                {data.name.charAt(0).toUpperCase() +
                                  data.name.slice(1)}
                              </>
                            )}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>

            {/* Loyality Points */}
            {/* <div className='w-[98%] h-[2.5rem] mx-[1%] flex items-center justify-center my-2 rounded-2xl border border-gray-light overflow-hidden'>
                            <div className='w-[40%] h-[2.5rem] float-left font-medium bg-gray-light flex items-center justify-start'>
                                <p className='w-[60%] h-auto float-left pl-4 text-xs'>Your Points&nbsp;:</p>
                                <p className='w-[40%] h-auto float-left text-xs text-primaryNavy truncate overflow-auto'>1150</p>
                            </div>
                            <div className='w-[60%] h-full float-left flex items-center justify-start'>
                                <input className='w-[75%] h-full float-left outline-none text-xs px-2' type='number' name='loyalityPoint' value={inputPoint} onChange={handleLoyaltyInput} placeholder={`Reedem (Max ${loyalityPoint})`} />
                                <button className='w-[25%] h-full float-left bg-primaryBlue text-sm font-bold text-text-light rounded-r-xl' onClick={handleRedeemPoints}>Use</button>
                            </div>
                        </div> */}

            {/* Payment */}
            <div className="w-[100%] h-auto fixed bottom-0 py-2 float-left flex items-center justify-center">
              {selectedVendor ? (
                <button
                  className="w-max h-max py-2 px-8 my-2 shadow-md shadow-gray text-lg text-text-color-light font-bold rounded-4xl bg-primaryBlue fixed bottom-0"
                  onClick={handleProceedNowOrder}
                >
                  {/* {`Pay ${
                    cupDetails?.currency
                      ? cupDetails.currency.toUpperCase()
                      : "RM"
                  } ${cupDetails?.price ? cupDetails.price : "0"}`} */}
                  {`Pay with credits ${
                    cupDetails?.price ? cupDetails.price : "0"
                  }`}
                </button>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <div className="w-[100%] h-[5rem] flex items-center justify-center break-word text-md font-bold text-red">
            This cup is not availabe for order at this moment!
          </div>
        )}
      </div>
      <Helmet>
        <title>CHIPT | ORDER PREVIEW</title>
      </Helmet>
    </>
  );
};

export default Chipt_Cup_Order_Preview;
