import React from "react";
import { FaSearch } from "react-icons/fa";
const vendors = [
  {
    id: 1,
    distance: "0.01km",
    shopName: "Zus Coffee",
    location: "TTDI, Damansara",
  },
  {
    id: 2,
    distance: "0.01km",
    shopName: "Code Black",
    location: "TTDI, Damansara",
  },
  {
    id: 3,
    distance: "0.01km",
    shopName: "Zus Coffee",
    location: "TTDI, Damansara",
  },
];

const cupData = [
  {
    id: 1,
    img: "/assets/svg/nfc/bluecup.svg",
    name: "Chipt Circular Cup",
    cupId: "Unique Cup ID: CB:F9:43:3A",
  },
 
];

const SelectVendor = () => {
  return (
    <div className=" h-full px-5 flex flex-col justify-start ">
      <h2 className=" h-[50px] text-center mt-8 font-bold text-base leading-5">
        Select vendor
      </h2>
      <div className="w-full px-3">
        <div className=" mt-2 bg-[#eeeff3] p-4 rounded-3xl flex items-center gap-4 ">
          <FaSearch />
          <input
            type="text"
            className=" searchbar outline-none border-none flex-1 bg-transparent"
            placeholder="Search"
          />
        </div>
      </div>
      <div className=" mt-5">
        <h2 className=" text-[#1F2024] text-base leading-4  font-semibold text-start">
          Nearby Vendors
        </h2>
        {!vendors.length > 0 ? (
          <p className="py-5 text-center text-red-600 font-semibold ">
            Data Not Available
          </p>
        ) : (
          <div className="flex items-center gap-3 overflow-x-auto mt-[10px]">
            {vendors.map((ele) => (
              <div
                key={ele.id}
                className="max-w-[250px]  rounded-t-2xl shrink-0  overflow-hidden"
              >
                <div className="h-[120px] w-full">
                  <img
                    src="/assets/images/vendore/Image.png"
                    alt=""
                    className=" h-full w-full object-cover"
                  />
                </div>
                <div className=" p-4 bg-[#F8F9FE] rounded-b-2xl">
                  <h1 className=" text-sm  font-black leading-[16.94px] text-ellipsis whitespace-nowrap overflow-hidden">
                    {ele.shopName}
                  </h1>
                  <p className=" mt-1 text-[#71727A] text-xs leading-4  text-ellipsis whitespace-nowrap overflow-hidden">
                    {ele.location}
                  </p>
                  <button className=" mt-4 w-full border border-primary text-primary rounded-xl p-3 font-semibold">
                    Select
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className=" mt-1 pb-14">
        {!cupData.length > 0 ? (
          <p className=" text-red text-center py-10 font-semibold"></p>
        ) : (
          cupData.map((ele) => (
            <div
              className=" w-full flex rounded-2xl overflow-hidden mb-3"
              key={ele.id}
            >
              <div className=" h-full bg-[#EAF2FF] px-5 py-4 w-[67px]">
                <img src={ele.img} alt="" className=" h-9 w-9" />
              </div>
              <div className=" bg-[#F8F9FE] flex-1 p-4">
                <h1 className=" text-sm leading-[16.94px]  font-black">
                  {ele.name}
                </h1>
                <p className=" mt-1 text-[#71727A] text-xs leading-4  text-ellipsis whitespace-nowrap overflow-hidden">
                  {ele.cupId}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="  w-full flex justify-center mt-auto py-6">
          <button className=" p-3 border-black  border-[1.5px] w-full  text-sm font-semibold rounded-xl">
          Proceed
          </button>

        </div>
    </div>
  );
};

export default SelectVendor;
