import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionsContext } from "../../../context/Context";
import {
  Call_Get_Profile,
  Call_Update_Profile_Basic_Information,
  state_Customer_Profile,
  state_Is_Loading,
} from "../../../redux/Profile/CustomerProfileSlice";
import Project_Loader from "../../NavigationComponents/Project_Loader";

const Chipt_Contact_Update = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(state_Is_Loading);
  const customer = useSelector(state_Customer_Profile);
  const [userInfo, setUserInfo] = useState();
  const [callLoading, setCallLoading] = useState(false);
  const notificationContext = useContext(ActionsContext);

  const updateBasicInformation = (e) => {
    e.preventDefault();
    setCallLoading(true);
    notificationContext.loadRef.current.staticStart();
    dispatch(Call_Update_Profile_Basic_Information(userInfo)).then((data) => {
      if (data.payload) toast.success("Information has been updated!");
      dispatch(Call_Get_Profile()).then((data) => {
        if (data.payload && data.payload.customer) {
          setUserInfo(data.payload.customer);
          navigate("/profile");
        }
      });
      setCallLoading(false);
      notificationContext.loadRef.current.complete();
    });
    notificationContext.loadRef.current.complete();
  };

  const handleContactChange = (e) => {
    e.preventDefault();
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (customer) {
      if (customer.data) {
        if (customer.data.customer) {
          setUserInfo(customer.data.customer);
        }
      } else {
        setUserInfo(customer.customer);
      }
    } else if (!callLoading) {
      dispatch(Call_Get_Profile()).then((data) => {
        if (data.payload) {
          setUserInfo(data.payload.customer);
        }
      });
    } else {
      dispatch(Call_Get_Profile()).then((data) => {
        if (data.payload) {
          setUserInfo(data.payload.customer);
        }
      });
    }
  }, [isLoading, callLoading]);

  return (
    <>
      {callLoading ? (
        <div className="w-full lg:w-[40%] h-full absolute">
          <Project_Loader />
        </div>
      ) : (
        <>
          <div className="w-[100%] ">
            <form className=" flex flex-col">
              <div className="">
                <h2 className="text-xs font-bold mb-4">Name</h2>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={userInfo?.name ? userInfo.name : ""}
                  onChange={handleContactChange}
                  className="block px-4 py-[14px] w-full text-sm leading-5 bg-transparent rounded-xl border border-bordercolor hover:outline-primary"
                  placeholder="Name"
                />
              </div>

              <div className="rounded-lg mt-4 mb-1">
                <h2 className="text-xs font-bold mb-4">Primary Email</h2>
                <input
                  type="text"
                  id="primaryEmail"
                  name="primaryEmail"
                  value={userInfo?.primaryEmail ? userInfo.primaryEmail : ""}
                  onChange={handleContactChange}
                  className="block px-4 py-[14px] w-full text-sm leading-5 bg-transparent rounded-xl border border-bordercolor hover:outline-primary"
                  placeholder="Enter Primary Email"
                  autoComplete="true"
                />
              </div>
            </form>
            <div className="absolute left-0 right-0 bottom-0 flex items-center justify-start">
              <button
                className="w-full bg-white text-sm text-primary border border-primary font-bold py-3 px-2 rounded-xl"
                onClick={updateBasicInformation}
              >
                Save & Continue
              </button>
            </div>
          </div>
        </>
      )}
      <Helmet>
        <title>CHIPT | PROFILE UPDATE</title>
      </Helmet>
    </>
  );
};

export default Chipt_Contact_Update;
