import React, { useState } from "react";
import { Step, Stepper } from "react-form-stepper";

const Authorize = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [authorises, setAuthoRises] = useState(false);

  const handleNext = () => {
    if (activeStep < 2) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const authorisehandle = () => {
    setAuthoRises((prev) => !prev);
  };

  return (
    <div className="px-5 h-full flex flex-col justify-start">
      <h2 className=" h-14 text-center leading-4 text-sm font-black tracking-wide">
        Authorize
      </h2>
      <Stepper activeStep={activeStep} className="new-stepper">
        <Step label="Scan cup" onClick={() => handleStepClick(0)} />
        <Step label="Select Vendor" onClick={() => handleStepClick(1)} />
        <Step label="Authorize" onClick={() => handleStepClick(2)} />
      </Stepper>

      {/* <div className='step-content'>
        {activeStep === 0 && <div>Content for Step 1</div>}
        {activeStep === 1 && <div>Content for Step 2</div>}
        {activeStep === 2 && <div>Content for Step 3</div>}
      </div> */}

      {!authorises ? (
        <div className=" flex h-full flex-col">
          <div className="step-buttons w-full flex justify-between py-4">
            <button
              className=" p-2 border border-bordercolor rounded-md font-medium"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </button>
            <button
              className=" p-2 border border-bordercolor rounded-md font-medium"
              onClick={handleNext}
              disabled={activeStep === 2}
            >
              Next
            </button>
          </div>
          <div className=" h-[117px] flex flex-col justify-center">
            <h2 className=" text-base leading-4 font-black">
              Authorize Chipt Credits
            </h2>
            <p className=" mt-[10px] text-[#71727A] leading-5 font-normal">
              Authorize your available Chipt credits to complete the cup
              circulation process
            </p>
          </div>
          <div className=" py-4">
            <div className=" border-[#D4D6DD] border rounded-2xl p-4">
              <div className="flex items-center">
                <input
                  id="link-radio"
                  type="radio"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="link-radio"
                  className="ms-2 text-xs font-bold text-[#71727A] dark:text-gray-300"
                >
                  {" "}
                  Chipt Wallet
                </label>
              </div>
              <div className="mt-6 bg-[#EAF2FF] p-4 rounded-xl">
                <h2 className=" text-sm leading-5 font-normal">Wallet</h2>
                <p className=" mt-1 text-[#71727A] text-xs leading-5 font-normal">
                  Available Credits: 65
                </p>
              </div>
            </div>
          </div>
          <div className=" py-4 mt-auto w-full">
            <button onClick={authorisehandle} className="custom-button">
              Authorize
            </button>
          </div>
        </div>
      ) : (
        <div className=" flex h-full flex-col ">
          <div className="step-buttons w-full flex justify-between py-4">
            <button
              className=" p-2 border border-bordercolor rounded-md font-medium"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </button>
            <button
              className=" p-2 border border-bordercolor rounded-md font-medium"
              onClick={handleNext}
              disabled={activeStep === 2}
            >
              Next
            </button>
          </div>
          <div className=" h-[117px] flex flex-col justify-center">
            <h2 className=" text-base leading-4 font-black">
              Enjoy your drink, Victor!
            </h2>
            <p className=" mt-[10px] text-[#71727A] leading-5 font-normal">
              Remember to return your cup by 28th August 2024
            </p>
          </div>
          <div className="  mt-5">
            <div className=" h-[222px] w-[222px] mx-auto bg-[#6FBAFF] rounded-full flex  justify-center items-center">
              <img
                src="/assets/svg/right/RightButton.svg"
                alt=""
                className="  h-24 w-[130px]"
              />
            </div>
          </div>
          <div className=" py-4 mt-auto w-full" onClick={authorisehandle}>
            <button className="custom-button">Done</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Authorize;
