import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const createPaymentIntent = createAsyncThunk(
  "stripe/createPaymentIntent",
  async (_, { getState, rejectWithValue }) => {
    const { packageId, customerId } = getState().stripe;
    if (!packageId || !customerId) {
      return rejectWithValue("Package ID and Customer ID must be provided");
    }
    try {
      const response = await axios.post(
        "/api/v1/customer/package/create-payment-intent",
        { packageId, customerId }
      );
      return {
        publishableKey: response.data.publishableKey || null,
        clientSecret: response.data.clientSecret || null,
      };
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "An error occurred"
      );
    }
  }
);

const stripeSlice = createSlice({
  name: "stripe",
  initialState: {
    packageId: null,
    customerId: null,
    publishableKey: null,
    clientSecret: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    setPackageId: (state, action) => {
      state.packageId = action.payload;
    },
    resetStripeState: (state) => {
      state.packageId = null;
      state.customerId = null;
      state.publishableKey = null;
      state.clientSecret = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPaymentIntent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.publishableKey = action.payload.publishableKey;
        state.clientSecret = action.payload.clientSecret;
        state.isLoading = false;
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setCustomerId, setPackageId, resetStripeState } =
  stripeSlice.actions;

export const selectPublishableKey = (state) => state.stripe.publishableKey;
export const selectClientSecret = (state) => state.stripe.clientSecret;
export const selectIsLoading = (state) => state.stripe.isLoading;
export const selectError = (state) => state.stripe.error;
export const selectCustomerId = (state) => state.stripe.customerId;
export const selectPackageId = (state) => state.stripe.packageId;

export default stripeSlice.reducer;
