import React, { useContext, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToggle, useVibrate } from "react-use";
import { ActionsContext } from "../../context/Context";
// React Icons
import toast from "react-hot-toast";
import { IoIosArrowForward } from "react-icons/io";
import { MdEdit } from "react-icons/md";

// All Fetch API
import { Helmet } from "react-helmet";
import { state_Is_Active } from "../../redux/authentication/AccountStatusSlice";
import { state_Is_Authenticated } from "../../redux/authentication/CustomerAuthSlice";
import { Call_Customer_All_Cups_Bought } from "../../redux/orders/CustomerAllOrderedCups";
import {
  Call_Get_Profile_Image,
  state_Customer_Profile_Image,
  state_Is_Loading,
} from "../../redux/Profile/CustomerProfileImageSlice";
import { Call_Get_Profile } from "../../redux/Profile/CustomerProfileSlice";

const Chipt_Collapse_Nav = ({ logoutFun }) => {
  const sideNavBar = useContext(ActionsContext);
  const [customer, setCustomer] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customerImage, setCustomerImage] = useState();
  const is_Authenticated = useSelector(state_Is_Authenticated);
  const isActive = useSelector(state_Is_Active);
  const isLoading = useSelector(state_Is_Loading);
  const customerProfileImage = useSelector(state_Customer_Profile_Image);

  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [100], false);

  const changeOnNaigation = (e, navTo) => {
    e.preventDefault();
    switch (navTo) {
      case "profile":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          if (is_Authenticated) navigate("/profile");
          else toast("Please login!");
        }, 100);
        break;
      case "home":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/home");
        }, 100);
        break;
      case "auth":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/auth");
        }, 100);
        break;

      case "profileupdate":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/profile/update");
        }, 100);
        break;

      case "passwordchange":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/password/change");
        }, 100);
        break;

      case "allorderedcups":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        dispatch(Call_Customer_All_Cups_Bought());
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/all/ordered/cups");
        }, 100);
        break;

      case "allorders":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/all/orders");
        }, 100);
        break;

      case "alltransactions":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/all/transactions");
        }, 100);
        break;

      case "termcondition":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/termcondition");
        }, 100);
        break;

      case "privacypolicy":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/privacypolicy");
        }, 100);
        break;

      case "refundpolicy":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/refundpolicy");
        }, 100);
        break;

      case "signup":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/signup");
        }, 100);
        break;

      case "signin":
        setTimeout(() => {
          if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
        }, 0);
        setTimeout(() => {
          sideNavBar.setActiveNavBar(false);
          navigate("/signin");
        }, 100);
        break;

      default:
        console.log("Invalid navigation");
    }
  };

  useEffect(() => {
    if (is_Authenticated) {
      if (customerProfileImage) {
        if (customerProfileImage.data) {
          setCustomerImage(customerProfileImage.data.customer);
        } else setCustomerImage(customerProfileImage.customer);
      } else if (!customerProfileImage) {
        dispatch(Call_Get_Profile_Image()).then((data) => {
          if (data.payload) {
            setCustomerImage(data.payload.customer);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    dispatch(Call_Get_Profile()).then((data) => {
      if (data.payload) {
        setCustomer(data.payload.customer);
      }
    });
  }, []);

  useEffect(() => {}, [is_Authenticated, isLoading]);

  const gotToWallet = async () => {
    navigate("/wallet");
  };

  return (
    <>
      {/* Main container start */}
      <div className="w-[100%]   h-auto  bg-navBar-cbg">
        <OutsideClickHandler
          onOutsideClick={() => sideNavBar.setActiveNavBar(false)}
        >
          {/* Sub Container start */}
          <div className="w-full mx-auto">
            <div className="min-h-mx  h-auto flex items-center justify-center">
              <div className="w-[100%] h-auto text-center mt-14">
                {/* Profile Show */}
                {is_Authenticated && (
                  <div className="w-[100%] p-2 h-auto  flex items-center flex-col justify-center ">
                    <div
                      className=" rounded-full   h-20 w-20 relative  cursor-pointer"
                      onClick={(e) => changeOnNaigation(e, "profile")}
                    >
                      {isActive ? (
                        <img
                          className="w-20 h-20  rounded-full"
                          src={
                            customerImage
                              ? customerImage.profilePicture?.url
                              : "/assets/svg/avtar/Avatar.svg"
                          }
                          alt="user"
                        />
                      ) : (
                        <img
                          className="w-[6rem] lg:w-[8rem] h-[6rem] lg:h-[8rem]  rounded-full"
                          src={"/assets/svg/avtar/Avatar.svg"}
                          alt="user"
                        />
                      )}
                      <div>
                        <div className="w-6 absolute bottom-0 right-0 z-50  h-6 rounded-full p-1 bg-primary text-white flex items-center justify-center">
                          <MdEdit />
                        </div>
                      </div>
                    </div>
                    <div className="w-[100%]   mt-4 flex items-center justify-center">
                      {is_Authenticated && isActive ? (
                        <div className=" flex items-center justify-start">
                          <p className="text-base font-black py-1">
                            {customer
                              ? customer.username
                                ? customer.username
                                : customer.contact
                              : "User name"}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}

                {/* Menu Table */}

                <div className="w-[100%] h-auto flex items-center justify-start">
                  <div className="w-full h-auto  py-6 flex flex-col   rounded-xl text-[1rem] lg:text-md">
                    {is_Authenticated && (
                      <div
                        className="w-full h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD]  cursor-pointer"
                        onClick={(e) => changeOnNaigation(e, "home")}
                      >
                        <p className=" h-full  text-left text-[#1F2024]">
                          Home
                        </p>
                        <p className=" h-full text-xl flex items-center justify-center">
                          <IoIosArrowForward color="#8F9098" />
                        </p>
                      </div>
                    )}
                    {is_Authenticated && isActive ? (
                      <>
                        <div
                          className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full "
                          onClick={(e) => changeOnNaigation(e, "profileupdate")}
                        >
                          <p className=" h-full  text-left text-[#1F2024]">
                            Edit Profile
                          </p>
                          <p className=" h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward color="#8F9098" />
                          </p>
                        </div>

                        <div
                          className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full "
                          onClick={(e) =>
                            changeOnNaigation(e, "passwordchange")
                          }
                        >
                          <p className=" h-full  text-left text-[#1F2024]">
                            Change Password
                          </p>
                          <p className=" h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward color="#8F9098" />
                          </p>
                        </div>

                        <div
                          className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full "
                          onClick={(e) =>
                            changeOnNaigation(e, "allorderedcups")
                          }
                        >
                          <p className=" h-full  text-left text-[#1F2024]">
                            Ordered Cups
                          </p>
                          <p className=" h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward color="#8F9098" />
                          </p>
                        </div>

                        <div
                          className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full "
                          onClick={(e) => changeOnNaigation(e, "allorders")}
                        >
                          <p className=" h-full  text-left text-[#1F2024]">
                            Orders
                          </p>
                          <p className=" h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward color="#8F9098" />
                          </p>
                        </div>

                        <div
                          className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full "
                          onClick={(e) =>
                            changeOnNaigation(e, "alltransactions")
                          }
                        >
                          <p className=" h-full  text-left text-[#1F2024]">
                            Transactions
                          </p>
                          <p className=" h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward color="#8F9098" />
                          </p>
                        </div>

                        <div
                          onClick={() => {
                            gotToWallet();
                          }}
                          className="cursor-pointer  h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full "
                        >
                          <p className=" h-full  text-left text-[#1F2024]">
                            Wallet
                          </p>
                          <p className=" h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward color="#8F9098" />
                          </p>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full cursor-pointer"
                      onClick={(e) => changeOnNaigation(e, "termcondition")}
                    >
                      <p className=" h-full  text-left text-[#1F2024]">
                        Terms & Condition
                      </p>
                      <p className=" h-full text-xl flex items-center justify-center">
                        <IoIosArrowForward color="#8F9098" />
                      </p>
                    </div>

                    <div
                      className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full cursor-pointer"
                      onClick={(e) => changeOnNaigation(e, "privacypolicy")}
                    >
                      <p className=" h-full  text-left text-[#1F2024]">
                        Privacy Policy
                      </p>
                      <p className=" h-full text-xl flex items-center justify-center">
                        <IoIosArrowForward color="#8F9098" />
                      </p>
                    </div>

                    <div
                      className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full cursor-pointer"
                      onClick={(e) => changeOnNaigation(e, "refundpolicy")}
                    >
                      <p className=" h-full  text-left text-[#1F2024]">
                        Refund Policy
                      </p>
                      <p className=" h-full text-xl flex items-center justify-center">
                        <IoIosArrowForward color="#8F9098" />
                      </p>
                    </div>
                    <div className=" h-auto p-4  font-semibold flex items-center justify-between border-b border-[#D4D6DD] w-full">
                      {is_Authenticated ? (
                        <>
                          <button
                            className="h-full  text-left text-[#1F2024]"
                            onClick={() => logoutFun()}
                          >
                            Logout
                          </button>
                          <p className=" h-full text-xl flex items-center justify-center">
                            <IoIosArrowForward color="#8F9098" />
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            className="w-full py-2.5 bg-primaryBlue hover:bg-primaryBlueHover text-white font-bold rounded-4xl mt-[10rem] lg:mt-[1rem] mx-2 cursor-pointer"
                            onClick={(e) => changeOnNaigation(e, "auth")}
                          >
                            Sign In / Sign Up
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {is_Authenticated && (
                  <div className=" py-6 px-4">
                    <button className=" text-sm  text-center w-full font-semibold p-3 text-primary border border-primary rounded-xl">
                      Purchase/Return Cup
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Sub Container end */}
        </OutsideClickHandler>
      </div>
      {/* Main container end */}
      <Helmet>
        <title>CHIPT | NAVIGATION</title>
      </Helmet>
    </>
  );
};

export default Chipt_Collapse_Nav;
