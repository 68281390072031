import React from "react";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const data = [
  {
    id: 1,
    img: "/assets/svg/slider/slider/slider.svg",
    tilte: "EMPOWERING SUSTAINABLE CHOICES",
    subheading: "Join our mission to eliminate all single-use coffee cups.",
  },
  {
    id: 2,
    img: "/assets/svg/slider/slider/slider.svg",
    tilte: "CREATING BETTER FUTURE",
    subheading: "Join our mission to eliminate all single-use coffee cups.",
  },
  {
    id: 3,
    img: "/assets/svg/slider/slider/slider.svg",
    tilte: "SAVING OUR ECOSYSTEM",
    subheading: "Join our mission to eliminate all single-use coffee cups.",
  },
];

const WelcomeSlider = () => {
  return (
    <div className="h-screen relative flex justify-center flex-col items-center p-2">
      <Swiper
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Pagination, Autoplay]}
        className="mySwiper welcome-slider max-w-[270px] mx-auto"
      >
        {data.length > 0 ? (
          data.map((ele) => (
            <SwiperSlide key={ele.id}>
              <div className="text-black p-1 flex flex-col justify-center items-center">
                <div className="h-[195px] w-[195px] mx-auto">
                  <img
                    className="w-full h-full object-cover"
                    src={ele.img}
                    alt="Chipt_Logo"
                  />
                </div>
                <div className="mt-[35px] text-center max-w-[263px] mx-auto text-xl font-bold leading-[24.38px]">
                  <h2>{ele.tilte}</h2>
                  <p className="mt-5 text-center max-w-[267px] mx-auto text-sm font-normal leading-[17.07px] text-[#71727A]">
                    {ele.subheading}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <p className="text-red-600 font-semibold text-xl">No Data Found</p>
        )}
      </Swiper>

      {
        <div className="w-[90%] mx-auto absolute bottom-8 left-1/2 transform -translate-x-1/2">
          <button className="custom-button">
            <NavLink to="/auth">Get started</NavLink>
          </button>
        </div>
      }
      <div className="w-[100%] absolute -bottom-5 left-0 right-0  h-auto flex items-center justify-center overflow-hidden">
        {/* <NFCScanBar /> */}
        <div className=" text-center font-medium pb-5 text-xs">
          2024 - Chipt © Daly Trading Sdn Bhd
        </div>
      </div>
    </div>
  );
};

export default WelcomeSlider;
