import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BiErrorCircle } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  Call_Get_All_Vendors,
  state_Is_Loading,
  state_Vendors,
} from "../../redux/authentication/AllVendorSlice";
import { state_Customer } from "../../redux/authentication/CustomerAuthSlice";
import {
  Call_Customer_All_Cups_Bought,
  state_Bought_Cups,
} from "../../redux/orders/CustomerAllOrderedCups";
import Loader from "../NavigationComponents/Chipt_Loader";
import Chipt_Show_Vendor from "../VendorComponents/Chipt_Show_Vendor";

import { useNavigate } from "react-router-dom";

const Chipt_Home_Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(state_Is_Loading);
  const vendors = useSelector(state_Vendors);
  const boughtCups = useSelector(state_Bought_Cups);
  const customer = useSelector(state_Customer);
  // const [searchTerm, setSearchTerm] = useState("");

  // const handleSearchValueChange = (e) => {
  //   e.preventDefault();
  //   setSearchTerm(e.target.value);
  //   dispatch(Call_Get_All_Vendors({ srch: e.target.value, loc: {} }));
  // };

  // const searchItemSubmit = (e) => {
  //   e.preventDefault();
  //   if (searchTerm)
  //     dispatch(Call_Get_All_Vendors({ srch: searchTerm, loc: {} }));
  // };

  useEffect(() => {
    dispatch(Call_Get_All_Vendors({ srch: "", loc: {} }));
    dispatch(Call_Customer_All_Cups_Bought());
  }, [dispatch]);

  return (
    <>
      <div className="w-[100%] min-h-[90vh] h-auto px-5 pb-10">
        <div className="w-[100%] h-auto">
          <div className="mt-[22px] uppercase font-semibold tracking-widest leading-5 text-base">
            Welcome,{" "}
            {customer?.name
              ? customer.name.toUpperCase()
              : customer?.firstname
              ? customer.firstname.toUpperCase()
              : ""}
            !
          </div>
          <div className="flex justify-start items-start py-4 gap-2 overflow-x-auto">
            <button className="shrink-0 text-[10px] bg-primary py-[6px] px-3 text-white rounded-full tracking-wider uppercase font-semibold">
              All Shops
            </button>
            {/* <button className="shrink-0 text-[10px] bg-primary py-[6px] px-3 text-white rounded-full tracking-wider uppercase font-semibold">
              Recommended
            </button>
            <button className="shrink-0 text-[10px] bg-[#EAF2FF] tracking-wider uppercase font-semibold py-[6px] px-3 text-primary rounded-full">
              Recently visited
            </button> */}
          </div>

          <div className="mt-3 pl-2">
            <div className="flex justify-between items-center">
              <h6 className="text-sm leading-4 font-extrabold">All Shops</h6>
            </div>
          </div>

          <div className="w-[100%] h-auto mt-[13px]">
            <div className="w-[100%] h-auto">
              {isLoading ? (
                <div className="w-[100%] flex items-center justify-center">
                  <Loader />
                </div>
              ) : vendors?.success && vendors.vendors.length > 0 ? (
                <Chipt_Show_Vendor vendorsData={vendors.vendors} />
              ) : (
                <div className="w-[100%] h-[30rem] flex items-center justify-center">
                  <div className="w-[100%] h-auto block text-center">
                    <p className="w-[100%] flex items-center justify-center">
                      <BiErrorCircle className="text-[5rem] text-gold" />
                    </p>
                    <p className="w-[100%] text-xs text-center break-word my-1">
                      No data exist related to what you are looking for!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Bought or Returned Cups Section */}
          <div className="mt-5 pb-8">
            <div className="flex justify-between items-center">
              <h6 className="text-sm leading-4 font-extrabold">Your Cups</h6>
            </div>
            <div className="w-[100%] h-auto mt-3 pb-3">
              {boughtCups?.success && boughtCups.cupList.length > 0 ? (
                boughtCups.cupList.slice(0, 2).map((cup) => (
                  <div
                    key={cup._id}
                    className="flex items-center bg-[#F8F9FE] rounded-2xl overflow-hidden mb-3"
                  >
                    <img
                      className="w-16 object-contain "
                      src={cup.cupID?.cupImages[0]?.url || "default_image_url"}
                      alt="Cup"
                    />
                    <div className=" p-4 flex w-full justify-between items-center">
                      <div>
                        <h5 className="text-sm font-extrabold">
                          {cup.cupID?.cupType || "Cup"}
                        </h5>
                        <p className=" text-xs text-[#71727A] leading-4 font-normal mt-1">
                          Bought on {new Date(cup.orderTime).toLocaleString()}
                        </p>
                        {/* <p className={cup.isReturned ? 'text-primaryBlue' : 'text-red'}>
                        {cup.isReturned ? 'Returned' : 'Not Returned'}
                      </p> */}
                      </div>
                      <IoIosArrowForward color="#8F9098" />
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-[100%] h-auto flex flex-col items-center justify-center gap-4 p-4 bg-[#F8F9FE] rounded-xl">
                  <p className="text-sm text-gray-500">No cups found.</p>
                  <button
                    onClick={() => navigate("/qr/reader")}
                    className="w-full p-3 border-black border-[1.5px] text-sm font-semibold rounded-xl"
                  >
                    Purchase / Return
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* credit */}
          <div>
            <div className="flex justify-between items-center">
              <h6 className="text-sm leading-4 font-extrabold">Credits</h6>
            </div>
          </div>

          {/* <div className=" py-6 px-4">
            <button className=" w-full text-primary border border-primary rounded-xl p-3 font-semibold text-xs">
              <NavLink to={"/purchase-return"}>purchase/return</NavLink>
            </button>
          </div> */}
          <div className="w-full flex justify-center py-6 px-4 mt-auto">
            <button
              className="custom-button"
              onClick={() => navigate("/packages")}
            >
              Packages
            </button>
          </div>
        </div>
        <div className="w-[100%] h-auto flex items-center justify-center overflow-hidden">
          {/* <NFCScanBar /> */}
          <div className=" text-center font-medium text-xs">
            2024 - Chipt © Daly Trading Sdn Bhd
          </div>
        </div>
      </div>
      <Helmet>
        <title>CHIPT | HOME</title>
      </Helmet>
    </>
  );
};

export default Chipt_Home_Dashboard;
