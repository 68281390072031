import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MdEmail, MdPhone, MdLocationPin } from "react-icons/md";
import vendor_store from "../asset/admin.jpg";
import NFCScanBar from "../NavigationComponents/Chipt_Footer_NFC_Bar";
import {
  Call_Vendor_Store_Cups,
  state_Vendor_Store_Data,
} from "../../redux/authentication/StoreAllCupSlice";
import ChiptShowVendorStoreData from "./Chipt_Show_Vendor_Store";
import Project_Loader from "../NavigationComponents/Project_Loader";
import { Helmet } from "react-helmet";

const Chip_Vendor_Store = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const vendor_Data = useSelector(state_Vendor_Store_Data);
  const vendorStoreCup = useSelector(state_Vendor_Store_Data);
  const [filterTerm, setFilterTerm] = useState("small");
  const [filterBtn, setFilterBtn] = useState([]);
  const [cups, setCups] = useState();
  const [storeImage, setStoreImage] = useState(false);
  const [callLoader, setCallLoader] = useState(false);

  const settingFiterValue = (e) => {
    e.preventDefault();
    setFilterTerm(e.target.value);
  };

  useEffect(() => {
    setCallLoader(true);
    dispatch(Call_Vendor_Store_Cups(id)).then((data) => {
      setCallLoader(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (vendor_Data) {
      if (vendor_Data.success & (vendor_Data.vendorStock.cups !== false)) {
        let temp = filterBtn;
        vendor_Data.vendorStock.cups.forEach((data) => {
          if (!temp.includes(data.cupSize.toLowerCase())) {
            filterBtn.push(data.cupSize.toLowerCase());
          }
        });
        setFilterBtn(temp);
        setCups(vendor_Data.vendorStock.cups);
      } else setCups(vendor_Data.vendorStock.cups);
      if (
        vendor_Data.vendorStock.storeGallery &&
        vendor_Data.vendorStock.storeGallery.length > 0
      ) {
        setStoreImage(vendor_Data.vendorStock.storeGallery);
      }
    }
  }, [callLoader]);

  return (
    <>
      {/* Main container start */}
      {callLoader ? (
        <div className="w-[100%] h-screen">
          <Project_Loader />
        </div>
      ) : (
        <>
          <div className="w-[100%] min-h-screen h-auto">
            {/* Sub Container start */}
            <div className="w-[100%] h-full float-left overflow-auto">
              {/* Content Start */}
              <div className="w-[100%] h-[10rem] flex items-center justify-center overflow-hidden">
                {vendorStoreCup?.vendorStock ? (
                  <>
                    <div className="w-[40%] h-[10rem] p-1 flex items-center justify-center float-left overflow-hidden">
                      <div className="w-max h-max rounded-full overflow-hidden outline-dashed outline-offset-4 outline-primaryBlue">
                        <img
                          className="w-[7.5rem] h-[7.5rem] rounded"
                          src={
                            vendorStoreCup?.vendorStock?.profilePicture
                              ? vendorStoreCup?.vendorStock?.profilePicture.url
                              : vendor_store
                          }
                          alt="vendor_store"
                        />
                      </div>
                    </div>
                    <div className="w-[60%] h-[10rem] px-2 flex items-center justify-start float-left overflow-hidden">
                      <div className="block w-full h-auto">
                        <div className="w-[100%] h-auto text-lg font-bold truncate overflow-hidden">
                          {vendorStoreCup?.vendorStock?.name
                            ? vendorStoreCup.vendorStock.name
                                .charAt(0)
                                .toUpperCase() +
                              vendorStoreCup.vendorStock.name.slice(1)
                            : ""}
                        </div>
                        <div className="w-[100%] h-auto text-sm font-medium flex items-center justify-start">
                          <span className="w-[10%] text-sm sm:text-xs text-gray">
                            <MdEmail />
                          </span>
                          <p className="w-[90%] lg:w-[95%] truncate overflow-hidden">
                            &nbsp;
                            {vendorStoreCup?.vendorStock?.primaryEmail
                              ? vendorStoreCup.vendorStock.primaryEmail
                              : ""}
                          </p>
                        </div>

                        <div className="w-[100%] h-auto text-sm font-medium flex items-center justify-start">
                          <span className="w-[10%] text-sm sm:text-xs text-gray">
                            <MdPhone />
                          </span>
                          <p className="w-[90%] lg:w-[95%] truncate overflow-hidden">
                            &nbsp;
                            {vendorStoreCup?.vendorStock?.contact
                              ? vendorStoreCup.vendorStock.contact
                              : ""}
                          </p>
                        </div>

                        <div className="w-[100%] h-auto text-sm font-normal flex items-center justify-start break-word overflow-hidden">
                          <span className="w-[10%] h-auto text-sm sm:text-xs text-gray">
                            <MdLocationPin />
                          </span>
                          <p className="w-[90%] h-auto truncate overflow-hidden">
                            &nbsp;
                            <i>
                              {vendorStoreCup?.vendorStock?.address
                                ? vendorStoreCup.vendorStock.address
                                : ""}
                            </i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="w-full h-full flex items-center justify-center break-all font-bold">
                    No data found
                  </div>
                )}
              </div>

              <div className="w-[100%] min-h-[32rem] h-auto bg-vendor-bg rounded-4xl my-4 mb-16 float-left overflow-auto">
                <div className="w-[90%] mx-[5%] flex flex-wrap py-4">
                  {cups ? (
                    <>
                      <div className="w-[100%] min-h-[3rem] flex items-center justify-center">
                        <div className="w-[100%] min-h-[2rem] h-auto px-2">
                          {filterBtn.map((data, index) => {
                            return (
                              <button
                                key={index}
                                value={data}
                                className="hover:shadow w-fit h-[1.75rem] px-2 mr-2 text-xs text-white font-medium bg-filter-btn-bg hover:bg-gray-dark rounded-2xl"
                                onClick={settingFiterValue}
                              >
                                {data.charAt(0).toUpperCase() + data.slice(1)}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      {cups.map((data) => {
                        if (
                          data.cupSize.toLowerCase() ===
                          filterTerm.toLowerCase()
                        ) {
                          return (
                            <ChiptShowVendorStoreData
                              key={data.cupID}
                              storeData={data}
                            />
                          );
                        }
                      })}
                    </>
                  ) : (
                    <div className="w-[100%] h-[30rem] flex items-center justify-center text-2xl font-bold">
                      No data found
                    </div>
                  )}
                </div>
              </div>

              {storeImage ? (
                <div className="w-full h-auto mb-20 overflow-auto">
                  <div className="w-full h-16 flex items-center justify-center text-xl font-bold">
                    Store Gallery
                  </div>
                  <div className="w-full min-h-[12rem] h-auto overflow-auto flex flex-wrap items-center justify-center">
                    {storeImage.map((data, index) => {
                      return (
                        <div
                          key={data.public_id}
                          className="w-[10rem] hover:w-[11rem] h-[8rem] hover:h-[9rem] mx-2 hover:mx-0 my-6 hover:my-4 rounded-md shadow hover:shadow-md hover:shadow-gray bg-white overflow-hidden"
                        >
                          <img
                            className="w-full h-full"
                            src={data.url}
                            alt={"Store_Image_" + index}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* Content end */}
            </div>
            {/* Sub Container end */}
            <div className="w-[100%] h-auto flex items-center justify-center overflow-hidden">
              {/* <NFCScanBar /> */}
              <div className=" text-center font-medium pb-5 text-xs">
                2024 - Chipt © Daly Trading Sdn Bhd
              </div>
            </div>
          </div>
        </>
      )}

      {/* Main container end */}
      <Helmet>
        <title>CHIPT | VENDOR STORE</title>
      </Helmet>
    </>
  );
};

export default Chip_Vendor_Store;
