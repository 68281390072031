import axios from "axios";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import OtpInput from "react-otp-input";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ActionsContext } from "../../../context/Context";
import {
  Call_Sign_Up_Auth,
  Call_Verify_Otp,
  state_Has_Error,
  state_Is_Authenticated,
} from "../../../redux/authentication/CustomerAuthSlice";
import Chipt_Security_Footer from "../../NavigationComponents/Chipt_Security_Footer";
import Project_Loader from "../../NavigationComponents/Project_Loader";

const ChiptAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chiptAction = useContext(ActionsContext);

  const [view, setView] = useState("login"); // login, otp, signup
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [callLoader, setCallLoader] = useState(false);
  const [countryCode, setCountryCode] = useState("MY");
  const [trmCond, setTRMCond] = useState(false);
  const isAuthenticated = useSelector(state_Is_Authenticated);
  const error = useSelector(state_Has_Error);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleSendOtp = async () => {
    setCallLoader(true);
    const fullPhoneNumber = `+${getCountryCallingCode(
      countryCode
    )}${phoneNumber}`;
    try {
      await axios.post("/api/v1/customer/account/send-otp", {
        phoneNumber: fullPhoneNumber,
      });
      setCallLoader(false);
      setView("otp");
    } catch (error) {
      console.error("Error during OTP send", error);
      toast.error("Error during OTP send");
      setCallLoader(false);
    }
  };

  const handleVerifyOtp = () => {
    setCallLoader(true);
    const countryCallingCode = `+${getCountryCallingCode(countryCode)}`;
    const fullPhoneNumber = `${countryCallingCode}${phoneNumber}`;

    dispatch(
      Call_Verify_Otp({
        countryCode: countryCallingCode,
        phoneNumber: fullPhoneNumber,
        otp,
      })
    )
      .then((res) => {
        handleResponse(res.payload);
        setCallLoader(false);
      })
      .catch((error) => {
        console.error("Error during OTP verification", error);
        toast.error("Error during OTP verification");
        setCallLoader(false);
      });
  };

  const handleResponse = (res) => {
    if (res) {
      if (
        res.message === "Failed to verify OTP" ||
        res.message === "Invalid OTP"
      ) {
        toast.error("Invalid OTP", { duration: 2000 });
        return;
      } else if (res.customerNotFound) {
        setView("signup");
        return;
      } else if (res.blocked) {
        toast.error("Dear user, your account has been suspended by admin!", {
          duration: 5000,
          position: "top-center",
          style: {
            fontSize: "0.8rem",
          },
          className: "",
          icon: "🛑",
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
        return;
      } else {
        toast.success("Login Successful", { duration: 2000 });
        Cookies.set("token", res.token, { expires: 7 });
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleSignUp = async () => {
    if (!countryCode || !phoneNumber || !name) {
      toast.error("Country code, phone number, and name are required!");
      return;
    }
    if (!trmCond) {
      toast.error("You must agree to the terms and conditions to sign up!");
      return;
    }

    if (name.length < 3) {
      toast.error("Name length should be at least 3 characters!");
      return;
    }
    setCallLoader(true);
    chiptAction.loadRef.current.staticStart();

    const data = {
      countryCode: getCountryCallingCode(countryCode),
      phoneNumber,
      name,
    };

    dispatch(Call_Sign_Up_Auth(data)).then((res) => {
      handleResponse(res.payload && res.payload.success === true);
      chiptAction.loadRef.current.complete();
      setCallLoader(false);
      if (res.payload) {
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      }
    });
  };

  const handleEditNumber = () => {
    setView("login");
  };

  return (
    <>
      {callLoader ? (
        <div className="w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center">
          <Project_Loader />
        </div>
      ) : (
        <></>
      )}
      <div className="w-full px-8 pb-8 h-full mx-auto">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col justify-center items-center">
            {view === "login" && (
              <>
                <h1 className="font-medium leading-[24.38px] text-xl mb-4">
                  Log in or Sign up
                </h1>
                <div className="w-full mt-[14px] mb-4">
                  <div className="flex items-center border px-4 gap-3 bg-white py-[14px] border-bordercolor rounded-xl overflow-hidden">
                    <div className="w-[30%] pr-3 border-r-2 border-bordercolor">
                      <select
                        type="text"
                        id="countrySelect"
                        name="countrySelect"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        className="block w-full text-sm bg-transparent outline-none hover:border peer"
                        placeholder=" "
                        autoComplete="on"
                      >
                        <option value="">{en.ZZ}</option>
                        {getCountries().map((country) => (
                          <option key={country} value={country}>
                            {getCountryCallingCode(country)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full">
                      <input
                        type="number"
                        id="contactNumber"
                        name="contactNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="block w-full text-sm bg-transparent peer"
                        placeholder="Phone number"
                        autoComplete="true"
                        required
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="custom-button mt-4"
                  onClick={handleSendOtp}
                  disabled={callLoader}
                >
                  {callLoader ? "Sending OTP..." : "Continue"}
                </button>
                <div id="recaptcha-container"></div>
              </>
            )}
            {view === "otp" && (
              <>
                <h1 className="font-medium leading-[24.38px] text-xl mb-4">
                  Enter OTP
                </h1>
                <p className="mb-4">
                  OTP sent to{" "}
                  {`+${getCountryCallingCode(countryCode)}${phoneNumber}`}
                  <button
                    className="ml-2 text-blue-500 underline"
                    onClick={handleEditNumber}
                  >
                    Edit
                  </button>
                </p>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} type="number" />}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                  }}
                  inputStyle={{
                    width: "48px",
                    border: "1px solid #C5C6CC",
                    textAlign: "center",
                    outline: "none",
                    height: "48px",
                    borderRadius: "12px",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                />
                <button
                  className="custom-button mt-4"
                  onClick={handleVerifyOtp}
                  disabled={callLoader}
                >
                  {callLoader ? "Verifying OTP..." : "Continue"}
                </button>
              </>
            )}
            {view === "signup" && (
              <>
                <h1 className="font-medium leading-[24.38px] text-xl mb-4">
                  Sign up
                </h1>
                <div className="w-full mt-[14px] mb-4">
                  <div className="flex items-center border px-4 gap-3 bg-white py-[14px] border-bordercolor rounded-xl overflow-hidden">
                    <div className="w-[30%] pr-3 border-r-2 border-bordercolor">
                      <select
                        type="text"
                        id="countrySelect"
                        name="countrySelect"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        className="block w-full text-sm bg-transparent outline-none hover:border peer"
                        placeholder=" "
                        autoComplete="on"
                        disabled={view === "signup"}
                      >
                        <option value="">{en.ZZ}</option>
                        {getCountries().map((country) => (
                          <option key={country} value={country}>
                            {getCountryCallingCode(country)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full">
                      <input
                        type="number"
                        id="contactNumber"
                        name="contactNumber"
                        value={phoneNumber}
                        readOnly
                        className="block w-full text-sm bg-transparent peer"
                        placeholder="Phone number"
                        autoComplete="true"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full mt-[14px] mb-4">
                  <div className="block text-xs font-bold leading-[14.52px] mb-2">
                    Name
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block px-4 py-[14px] w-full text-sm bg-transparent rounded-lg appearance-none border border-bordercolor hover:border peer hover:outline-primary"
                    placeholder="Name"
                    autoComplete="true"
                    required
                  />
                </div>
                <div className="w-[100%] mt-[18px] h-auto flex items-center justify-center overflow-auto">
                  <div className="flex flex-wrap text-xs">
                    <input
                      type="checkbox"
                      className="w-4 h-4 mr-2"
                      onClick={() => setTRMCond(!trmCond)}
                    />
                    <div className="w-max text-xs lg:font-medium leading-[12.91px]">
                      By continuing, I agree to&nbsp;&nbsp;
                    </div>
                    <Link
                      to="/privacypolicy"
                      className="font-medium lg:font-bold underline decoration-1"
                    >
                      Privacy Policy
                    </Link>
                    ,&nbsp;
                    <Link
                      to="/termcondition"
                      className="font-medium lg:font-bold underline decoration-1"
                    >
                      Term & Condition
                    </Link>
                    ,&nbsp;
                    <span className="lg:font-medium">&nbsp;and&nbsp;</span>
                    <Link
                      to="/refundpolicy"
                      className="font-medium lg:font-bold underline decoration-1"
                    >
                      Refund Policy
                    </Link>
                  </div>
                </div>
                <button
                  className="custom-button mt-4"
                  onClick={handleSignUp}
                  disabled={!trmCond || callLoader}
                >
                  {callLoader ? "Signing Up..." : "Agree and continue"}
                </button>
              </>
            )}
          </div>
        </div>
        <Chipt_Security_Footer />
      </div>
      <Helmet>
        <title>CHIPT | AUTHENTICATION</title>
      </Helmet>
    </>
  );
};

export default ChiptAuth;
