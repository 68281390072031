import React from "react";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const navigate = useNavigate();

  const handlePayClick = () => {
    navigate("/stripe-payment");
  };

  return (
    <>
      <div className="flex h-full flex-col">
        <h2 className="text-center font-bold">Payment</h2>
        <div className="h-[117px] flex flex-col justify-center">
          <h2 className="text-base leading-4 font-black">
            Select a payment option
          </h2>
          <p className="mt-[10px] text-[#71727A] leading-5 font-normal">
            Authorize your TouchNGo E-wallet into Chipt to enjoy maximum
            benefits and easy payment!
          </p>
        </div>
        <div className="py-4">
          <div className="border-[#D4D6DD] border rounded-2xl p-4">
            <div className="flex items-center">
              <input
                id="link-radio"
                type="radio"
                value=""
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                defaultChecked
              />
              <label
                htmlFor="link-radio"
                className="ms-2 text-xs font-bold text-[#71727A] dark:text-gray-300"
              >
                Stripe
              </label>
            </div>
            <div className="mt-6 bg-[#EAF2FF] p-4 rounded-xl">
              <h2 className="text-sm leading-5 font-normal">
                Link your account
              </h2>
              <p className="mt-1 text-[#71727A] text-xs leading-5 font-normal">
                Selected
              </p>
            </div>
          </div>
        </div>
        <div className="py-4 mt-auto w-full">
          <button className="custom-button" onClick={handlePayClick}>
            Pay
          </button>
        </div>
      </div>
    </>
  );
};

export default Payment;
