import React from "react";
import { NavLink } from "react-router-dom";

const PurchaseReturn = () => {
  return (
    <div className=" px-9 w-full h-full flex flex-col justify-center items-center">
      <div className=" p-4">
        <h1 className=" text-center text-base font-semibold">
          Select an option below
        </h1>
        <p className=" mt-2 text-xs font-normal text-[#71727A] leading-4">
          We provide scanning via both NFC and QR for phones that does not
          support NFC
        </p>
        <div className=" mt-5 flex justify-center items-center gap-2">
          <button className=" p-3 w-full  text-primary border border-primary  rounded-xl">
            <NavLink to={"/nfc"}>NFC</NavLink>
          </button>
          <button className=" custom-button">
          <NavLink to={"/qr"}>QR</NavLink>
          </button>

        </div>
      </div>
    </div>
  );
};

export default PurchaseReturn;
