import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createPaymentIntent,
  selectClientSecret,
  selectCustomerId,
  selectError,
  selectIsLoading,
  selectPackageId,
  selectPublishableKey,
} from "../../redux/PaymentSlice/StripeSlice";
import Project_Loader from "../NavigationComponents/Project_Loader";
import StripeCheckoutForm from "./StripeCheckoutForm";

const StripePayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const publishableKey = useSelector(selectPublishableKey);
  const clientSecret = useSelector(selectClientSecret);
  const isLoading = useSelector(selectIsLoading);
  const customerId = useSelector(selectCustomerId);
  const packageId = useSelector(selectPackageId);
  const error = useSelector(selectError);

  useEffect(() => {
    if (!customerId || !packageId) {
      navigate("/packages");
    } else if (!clientSecret) {
      dispatch(createPaymentIntent());
    }
  }, [dispatch, clientSecret, customerId, packageId, navigate]);

  const stripePromise = publishableKey ? loadStripe(publishableKey) : null;

  return (
    <>
      {isLoading && (
        <div className="w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center">
          <Project_Loader />
        </div>
      )}
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      {clientSecret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            externalPaymentMethodTypes: ["external_tng_ewallet"],
          }}
        >
          <StripeCheckoutForm />
        </Elements>
      )}
    </>
  );
};

export default StripePayment;
