import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { state_Customer as selectCustomer } from "../../redux/authentication/CustomerAuthSlice";
import Project_Loader from "../NavigationComponents/Project_Loader";
import "./WalletMain.css";

const WalletMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useSelector(selectCustomer);
  const [wallet, setWallet] = useState(null);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchWalletData = async (id) => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/api/v1/customer/wallet/${id}`);
        if (response.data && response.data.data) {
          setWallet(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (customer && customer._id) {
      fetchWalletData(customer._id);
    }
  }, [dispatch, customer]);

  const handleWithdraw = async () => {
    try {
      const confirmWithdraw = window.confirm(
        "Are you sure you want to withdraw?"
      );
      if (!confirmWithdraw) {
        return;
      }
      const response = await axios.post("/api/v1/customer/wallet/withdraw", {
        customerId: customer._id,
      });
      if (response.data.success) {
        toast.success(
          "Withdraw completed, you will get refunded your account soon."
        );
      }
    } catch (error) {
      console.error("Error processing withdrawal:", error);
      toast.error("Withdraw cant be done");
    }
  };

  const redirectToPricingPage = () => {
    navigate("/packages");
  };

  const redirectToScanCap = () => {
    navigate("/qr/reader");
  };

  if (loading && !wallet) {
    return (
      <div className="w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center">
        <Project_Loader />
      </div>
    );
  }

  const securityDeposit = wallet?.securityDeposit;

  return (
    <div>
      <div className=" flex flex-col h-full justify-start  px-5">
        <div className=" mt-[35px] py-4">
          <h2 className="text-2xl font-semibold text-start">Chipt Wallet</h2>
        </div>
        <div className="mt-[38px] pb-20">
          {wallet ? (
            <div className=" w-full  px-3 ">
              <div>
                <div className="bg-[#EAF2FF] p-3 rounded-[10px] ">
                  <h1 className="text-sm leading-5 font-semibold text-start">
                    Balance
                  </h1>
                  <h2 className=" text-[64px] leading-10 font-light text-center mt-4">
                    {wallet.cupCredits}
                  </h2>
                  <p className="text-xl text-gray-700 text-nowrap  text-center mt-1 pb-7">
                    Credits
                  </p>
                </div>
                <button
                  onClick={redirectToPricingPage}
                  className=" p-3 bg-[#494A50] text-white rounded-xl w-full mt-[18px]  focus:outline-none"
                >
                  Buy More
                </button>
              </div>
              <div className="mt-[34px]">
                <div className=" bg-[#EAF2FF] p-3 rounded-[10px] ">
                  <h1 className="text-sm leading-5 font-semibold text-start">
                    Member activation Fees
                  </h1>
                  <p className="text-[64px] leading-10 font-light text-center mt-8 pb-10">
                    RM {securityDeposit}
                  </p>
                </div>
                <button
                  onClick={handleWithdraw}
                  className=" p-3 bg-[#494A50] text-white rounded-xl w-full mt-[18px]  focus:outline-none"
                >
                  Withdraw
                </button>
              </div>
              <div className=" py-6 mt-4">
                <button className="custom-button" onClick={redirectToScanCap}>
                  Scan your Cup
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              <h2 className="text-2xl font-semibold text-center text-gray-700">
                Wallet Not Found
              </h2>
              <p className="text-gray-500 text-center mt-2">
                We couldn't find your wallet information. Please try again later
                or contact support.
              </p>
              <button
                onClick={redirectToPricingPage}
                className="mt-4 p-3 bg-[#494A50] text-white rounded-xl focus:outline-none"
              >
                Go to Packages
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletMain;
