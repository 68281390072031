import React from "react";
import DataLoader from "../asset/dataLoader.gif";

const Project_Loader = () => {
  return (
    <div>
      <div className="w-[100%] h-screen flex items-center justify-center overflow-auto active backdrop-blur-sm">
        <div className="animate-bounce flex items-center justify-center">
          <img
            className="md:w-[60%] lg:w-[60%]"
            src={DataLoader}
            alt="data_loader"
          />
        </div>
      </div>
    </div>
  );
};

export default Project_Loader;
