import React, { useContext, useEffect, useState } from "react";
import { BiSolidError } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useToggle, useVibrate } from "react-use";
import Scan from "../../containers/NFCScan";
import { ActionsContext } from "../../context/Context";
import NFCTagImage from "../asset/nfcChip.svg";
import NFCGreenTag from "../asset/nfcChipHover.svg";
import Project_Loader from "../NavigationComponents/Project_Loader";

const NFCReader = () => {
  const NFCContext = useContext(ActionsContext);
  const [nfcImage, setNFCImage] = useState(NFCTagImage);
  const [scanBool, setScanBool] = useState(false);
  const [nfcAvailable, setNFCAvailable] = useState(true);
  const [btnMsg, setbtnMsg] = useState("Scan");
  const [callLoading, setCallLoading] = useState(false);
  const { scan, write } = NFCContext.actionsValue.actions || {};

  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [100], false);

  // Checking if NFC Available
  function checkNFCAvailable() {
    setNFCImage(NFCTagImage);
    setNFCAvailable(false);
  }

  // Scanning
  const onHandleAction = async (actions) => {
    // consoling the actions object from nfc reader

    NFCContext.actionsValue.setActions(actions);
    setbtnMsg("Scanning...");
    setNFCImage(NFCGreenTag);
    setScanBool(true);
    setTimeout(() => {
      toggleVibrating();
    }, 0);
    setTimeout(() => {
      toggleVibrating();
    }, 100);
  };

  // Cancel Button Function
  function handleScanCancel() {
    if (btnMsg === "Scanning...") {
      setbtnMsg("Scan");
      setScanBool(false);
      setNFCImage(NFCTagImage);
      NFCContext.actionsValue.setActions({
        ...NFCContext.actionsValue.actions,
        scan: null,
      });
    }
  }

  // Changing Message after scanned
  function changeBTNmsg(msg) {
    setbtnMsg(msg);
  }

  function handleCupCallHandler(statusVal) {
    if (statusVal === "true") setCallLoading(true);
    else if (statusVal === "false") {
      setCallLoading(false);
      setTimeout(() => {
        setbtnMsg("Scan");
        setScanBool(false);
        setNFCImage(NFCTagImage);
        NFCContext.actionsValue.setActions({
          ...NFCContext.actionsValue.actions,
          scan: null,
        });
      }, 1000);
    } else setCallLoading(false);
  }

  useEffect(() => {}, [btnMsg, callLoading]);

  return (
    <>
      {/* Main Container Start */}
      {callLoading ? <Project_Loader /> : <></>}
      <div className="w-[100%]  h-full  overflow-auto px-8 flex flex-col justify-center items-center">
        {/* Sub Container Start */}
        <div className="w-[100%] lg:w-[50%] lg:mx-[25%] h-auto pb-8 overflow-auto ">
          {nfcAvailable ? (
            <>
              <h2 className=" text-base h-[50px] font-medium text-center">
                SCAN YOUR CUP
              </h2>
              <div
                onClick={() =>
                  onHandleAction({ scan: "scanning", write: null })
                }
                className="w-[264px] cursor-pointer mx-auto mt-12 bg-primary rounded-full h-[267px] flex items-center justify-center overflow-hidden"
              >
                <img
                  className="w-[133px] h-[133px]"
                  src={"/assets/svg/nfc/coffee-cup.svg"}
                  alt="logo"
                />
              </div>
              {/* <div className="w-[100%]  flex items-center justify-center">
                <button
                  className="w-[45%] py-2 bg-primaryBlue text-xl font-bold text-text-color-light flex items-center justify-center rounded-lg"
                 
                >
                  {btnMsg}
                </button>
              </div> */}
              <div className="w-[100%] h-auto float-left overflow-auto mt-20">
                {scanBool ? (
                  <>
                    {scan && (
                      <Scan
                        changeFun={{
                          handleScanCancel,
                          changeBTNmsg,
                          checkNFCAvailable,
                          handleCupCallHandler,
                        }}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full text-center  mx-auto flex items-center justify-center text-sm break-words">
                    Tap on the Image and Put you device in NFC tag range of cup.
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="w-[100%] h-auto">
              <div className="w-[90%] mx-[5%] h-[20rem] float-left flex items-center justify-center">
                <p className="text-[18rem]">
                  <BiSolidError className="text-red" />
                </p>
              </div>
              <div className="w-[100%] h-[10vh] float-left flex items-center justify-center ">
                <p className="text-lg text-navy font-bold">
                  Current device doesn't support NFC
                </p>
              </div>
              <div className="w-[100%] h-[10vh] float-left flex items-center justify-center">
                <button className="bg-primaryBlue text-text-color-light mt-2 py-1 px-4 font-medium rounded text-lg">
                  <Link to="/home">Home</Link>
                </button>
              </div>
            </div>
          )}
        </div>
        {/* <div className="w-full flex justify-center mt-auto">
          <button className=" p-3 border-black  border-[1.5px] w-full  text-sm font-semibold rounded-xl">
            <NavLink to={"/select-vendor"}>Proceed</NavLink>
          </button>
        </div> */}
        {/* Sub Container End */}
      </div>
      {/* Main Container End */}
    </>
  );
};

export default NFCReader;
