import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import LoadingBar from "react-top-loading-bar";
import Home from "./components/Home/Chipt_Entry_Point";
import { ActionsContext } from "./context/Context";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
const token = Cookies.get("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
const App = () => {
  // axios.defaults.baseURL = "https://thebrandsmanager.com/";
  // axios.defaults.withCredentials = true;

  const [actions, setActions] = useState(null);
  const actionsValue = { actions, setActions };
  const loadRef = useRef(null);
  const [user, setUser] = useState();

  const [activeNavBar, setActiveNavBar] = useState(false);
  const [notiNavBar, setNotiNavBar] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  // Create a ref object to store the screen size
  const screenSize = useRef({ width: 0, height: 0 });

  // Define a function to update the screen size and the state variable
  const updateScreenSize = () => {
    screenSize.current = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    // setIsUpdated((prev) => !prev);
  };

  // Use useEffect hook to register and unregister the resize event listener
  useEffect(() => {
    // Update the screen size on initial render
    updateScreenSize();

    // Add the event listener
    window.addEventListener("resize", updateScreenSize);

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  return (
    <ActionsContext.Provider
      value={{
        activeNavBar,
        setActiveNavBar,
        notiNavBar,
        setNotiNavBar,
        isPopUp,
        setIsPopUp,
        screenSize,
        actionsValue,
        user,
        setUser,
        loadRef,
      }}
    >
      {/* <RouterProvider router={router} /> */}
      <Home />
      <LoadingBar color="#f11946" ref={loadRef} />
      <Toaster
        toastOptions={{
          className: "text-xs sm:text-xs",
          position: screenSize.current.width > 600 ? "top-right" : "top-center",
        }}
      />
    </ActionsContext.Provider>
  );
};

export default App;
