import React, { useContext } from "react";
import Loader from "../asset/spinner1.gif";

const Chipt_NFC_Scanner = ({ changeFun }) => {
  return (
    <div className="w-full mx-auto p-4  rounded-2xl bg-[#EAF2FF]  overflow-hidden">
      <div className="w-[100%]  gap-4 flex items-center justify-between ">
        <img src="/assets/svg/nfc/LeftIcon.svg" className=" h-6 w-6" alt=""/>
        <div>
          <h1 className=" text-sm font-bold leading-4">Scanning Started</h1>
          <p className="max-w-[180px] mt-1 text-[#494A50] text-xs font-normal">
            Place the bottom of the cup on your phone’s NFC scanner
          </p>
        </div>
        <button
          className=" rounded-ful text-[#71727A] text-xs font-bold "
          onClick={changeFun}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default Chipt_NFC_Scanner;
