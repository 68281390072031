import { BrowserQRCodeReader } from "@zxing/browser";
import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToggle, useVibrate } from "react-use";
import Project_Loader from "../components/NavigationComponents/Project_Loader";
import { ActionsContext } from "../context/Context";
import { state_Is_Authenticated } from "../redux/authentication/CustomerAuthSlice";
import { Call_Scanned_Cup_Details } from "../redux/orders/ScannedCupSlice";

const QrScanner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideNavBar = useContext(ActionsContext);
  const [vibrating, toggleVibrating] = useToggle(false);
  useVibrate(vibrating, [100], false);

  const isAuthenticated = useSelector(state_Is_Authenticated);
  const [btnLoading, setBtnLoading] = useState(false);

  const videoRef = useRef(null);
  const [scanning, setScanning] = useState(false);
  const [code, setCode] = useState(null);
  let barcodeReader = null;

  const startScanning = () => {
    setTimeout(() => {
      if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
    }, 0);
    setTimeout(() => {}, 100);
    barcodeReader = new BrowserQRCodeReader();
    barcodeReader
      .decodeOnceFromVideoDevice(undefined, videoRef.current)
      .then((result) => {
        setCode(result.text);
      })
      .catch((error) => {
        console.error("Barcode scanning error:", error);
      });
    setScanning(true);
    // // Temporary test code
    // setTimeout(() => {
    //   setCode("8D:3C:7E:F2");
    // }, 2000); // Simulate delay for scanning
  };

  const stopCamera = () => {
    // if (barcodeReader) {
    //   console.log(barcodeReader)
    //   // barcodeReader.stop();
    // }
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null; // Clear the srcObject to stop the video stream
    }
  };

  const cancelScanning = () => {
    stopCamera(); // Stop camera on cancel
    setScanning(false);
    setCode(null);
    // navigate('/home'); // Navigate to the home route
  };

  const proceedToOrder = () => {
    setBtnLoading(true);
    setTimeout(() => {
      if (sideNavBar.screenSize.current.width < 800) toggleVibrating();
    }, 0);
    setTimeout(() => {}, 100);
    if (!isAuthenticated) {
      toast.success(`Please login first`);
      setBtnLoading(false);
      navigate("/signin");
      return;
    }
    const temp = code;
    dispatch(Call_Scanned_Cup_Details({ uniqueID: code?.toUpperCase() })).then(
      (data) => {
        if (data.payload) {
          if (data.payload.success) {
            navigate(`/scanned/cup/${temp}`);
          } else {
            toast.error("No data found");
            setCode(null);
            navigate(`/home`);
          }
        } else {
          toast.error("No data found");
          setCode(null);
          navigate(`/home`);
        }
      }
    );
  };

  useEffect(() => {
    if (code) {
      proceedToOrder();
    }
  }, [code, scanning]);

  useEffect(() => {
    return () => {
      stopCamera(); // Stop camera when component unmounts
    };
  }, []);

  return (
    <div className="w-[100%] h-full overflow-auto px-5 pb-8 flex flex-col justify-center items-center">
      {btnLoading ? (
        <div className="w-[100%] h-screen absolute z-50">
          <Project_Loader />
        </div>
      ) : (
        <></>
      )}
      <div className="w-[100%] h-auto my-8  place-items-center">
        <div className="w-[100%] h-auto mt-4 mb-6 flex items-center justify-center">
          {/* <p className="text-2xl px-2 font-extrabold text-primaryNavy">
            <LuScanLine />
          </p> */}
          <p className="text-base h-[50px] font-medium text-primaryNavy">
            SCAN YOUR CUP
          </p>
        </div>
        <div className=" rounded-lg  flex justify-center items-center overflow-hidden">
          <div
            className={`w-56 h-56 absolute z-10 mt-[30px] flex items-center justify-center rounded-lg overflow-auto ${
              scanning || code ? "" : "bg-white"
            }`}
          >
            {scanning || code ? (
              <></>
            ) : (
              <>
                <img src="/assets/svg/qr/qr.svg" alt="" />
              </>
            )}
          </div>
          <video ref={videoRef} className="w-full h-full object-cover"></video>
        </div>
      </div>
      {scanning && code && (
        <div className="w-[80%] h-auto my-4 p-4 mx-[10%] float-left overflow-auto shadow-sm bg-white">
          <p className="text-center text-sm text-primaryNavy font-bold">
            CUP ID
          </p>
          <p className="w-[100%] h-[5rem] line-clamp-4 break-all text-center">
            {code}
          </p>
        </div>
      )}

      <div className=" w-full mt-auto py-5  h-auto p-2  bottom-0 flex items-center justify-center">
        {!scanning && (
          <button
            className="px-4 py-1.5 text-xl font-bold text-white bg-primary  w-full rounded-md"
            onClick={startScanning}
          >
            Scan
          </button>
        )}
        {scanning && (
          <button
            className="px-4 py-1.5 text-xl w-full font-bold text-white bg-red rounded-md"
            onClick={cancelScanning}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default QrScanner;
