import React, { useState, useEffect, useContext } from "react";
import {
  Call_Get_Profile,
  Call_Update_Profile_Address,
  state_Is_Loading,
  state_Customer_Profile,
} from "../../../redux/Profile/CustomerProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { ActionsContext } from "../../../context/Context";
import toast from "react-hot-toast";
import Project_Loader from "../../NavigationComponents/Project_Loader";
import { Helmet } from "react-helmet";

const Chipt_Address_Update = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state_Is_Loading);
  const customer = useSelector(state_Customer_Profile);
  const [userInfo, setUserInfo] = useState();
  const [callLoading, setCallLoading] = useState(false);
  const notificationContext = useContext(ActionsContext);

  const updateAddressInformation = (e) => {
    e.preventDefault();
    notificationContext.loadRef.current.staticStart();
    setCallLoading(true);
    dispatch(Call_Update_Profile_Address(userInfo)).then((data) => {
      if (data.payload) toast.success("Address has been updated!");
      dispatch(Call_Get_Profile()).then((data) => {
        if (data.payload) {
          setUserInfo(data.payload.customer);
        }
      });
      setCallLoading(false);
    });
    notificationContext.loadRef.current.complete();
  };

  const handleAddressChange = (e) => {
    e.preventDefault();
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (customer) {
      if (customer.data) {
        if (customer.data.customer) {
          setUserInfo(customer.data.customer);
        }
      } else {
        setUserInfo(customer.customer);
      }
    } else if (!isLoading) {
      dispatch(Call_Get_Profile()).then((data) => {
        if (data.payload) {
          setUserInfo(data.payload.customer);
        }
      });
    } else {
      dispatch(Call_Get_Profile()).then((data) => {
        if (data.payload) {
          setUserInfo(data.payload.customer);
        }
      });
    }
  }, [isLoading, callLoading]);

  return (
    <>
      {callLoading ? (
        <div className="w-[100%] md:-ml-[15%] lg:-ml-[25%] h-screen absolute flex items-center justify-center">
          <Project_Loader />
        </div>
      ) : (
        <>
          <div className=" bg-white p-2 px-4 overflow-auto">
            <form
              className="w-[100%] h-max float-left my-4"
              onSubmit={updateAddressInformation}
            >
              <div
                className={`${callLoading ? "" : "relative"} rounded-lg mt-2`}
              >
                <input
                  type="text"
                  id="plotnumber"
                  name="plotnumber"
                  value={
                    userInfo
                      ? userInfo?.plotnumber
                        ? userInfo.plotnumber
                        : ""
                      : ""
                  }
                  onChange={handleAddressChange}
                  className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue"
                  placeholder=" "
                  autoComplete="on"
                />
                <label
                  htmlFor="plotnumber"
                  className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Plot/Flat number
                </label>
              </div>

              <div
                className={`${callLoading ? "" : "relative"} rounded-lg mt-4`}
              >
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={
                    userInfo ? (userInfo?.address ? userInfo.address : "") : ""
                  }
                  onChange={handleAddressChange}
                  className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue"
                  placeholder=" "
                  autoComplete="on"
                />
                <label
                  htmlFor="address"
                  className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Address
                </label>
              </div>

              <div
                className={`${callLoading ? "" : "relative"} rounded-lg mt-4`}
              >
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={userInfo ? (userInfo?.city ? userInfo.city : "") : ""}
                  onChange={handleAddressChange}
                  className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue"
                  placeholder=" "
                  autoComplete="on"
                />
                <label
                  htmlFor="city"
                  className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  City
                </label>
              </div>

              <div
                className={`${callLoading ? "" : "relative"} rounded-lg mt-4`}
              >
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={
                    userInfo ? (userInfo?.state ? userInfo.state : "") : ""
                  }
                  onChange={handleAddressChange}
                  className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue"
                  placeholder=" "
                  autoComplete="on"
                />
                <label
                  htmlFor="state"
                  className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  State
                </label>
              </div>

              <div
                className={`${callLoading ? "" : "relative"} rounded-lg mt-4`}
              >
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={
                    userInfo ? (userInfo?.country ? userInfo.country : "") : ""
                  }
                  onChange={handleAddressChange}
                  className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue"
                  placeholder=" "
                  autoComplete="on"
                />
                <label
                  htmlFor="country"
                  className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Country
                </label>
              </div>

              <div
                className={`${callLoading ? "" : "relative"} rounded-lg mt-4`}
              >
                <input
                  type="number"
                  id="zipCode"
                  name="zipCode"
                  value={
                    userInfo ? (userInfo?.zipCode ? userInfo.zipCode : "") : ""
                  }
                  onChange={handleAddressChange}
                  className="block px-2 pb-2 pt-3 w-full text-sm bg-transparent rounded-lg appearance-none border border-gray hover:border peer hover:outline-primaryBlue"
                  placeholder=" "
                  autoComplete="on"
                />
                <label
                  htmlFor="zipCode"
                  className="absolute text-xs text-gray duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primaryBlue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  ZipCode
                </label>
              </div>
              <div
                className={`${
                  callLoading ? "" : "relative"
                } w-[100%] my-4 flex items-center justify-start`}
              >
                <button className="w-16 bg-primaryBlue text-sm text-white font-bold py-1.5 rounded-lg">
                  Save
                </button>
              </div>
            </form>
          </div>
        </>
      )}
      <Helmet>
        <title>CHIPT | ADDRESS UPDATE</title>
      </Helmet>
    </>
  );
};

export default Chipt_Address_Update;
