import React from "react";
import { Link } from "react-router-dom";
import Error404 from "../asset/404.gif";
import ChiptLogo from "../asset/chipt.svg";

const ErrorPage = () => {
  return (
    <div id="error-page" className="w-[80%] h-auto mx-[10%]">
      {/* Sub Container start */}
      <div className="w-[100%] min-h-[92vh] overflow-auto">
        {/* Project logo navigation */}
        {/* <div className='w-[100%] h-[10vh] float-left sm:h-[9vh] flex items-center justify-start'>
          <div className='float-left w-[50%] flex items-center justify-start'>
            <Link to='/home'>
              <img className='w-[60%]' src={ChiptLogo} alt="Chipt_Logo" />
            </Link>
          </div>

          <div className='float-right w-[50%] flex items-center justify-end'>
            <Link to='/profile/update'><GiHamburgerMenu className='text-2xl' /></Link>
          </div>
        </div> */}

        {/* Payment cup image */}
        <div className="h-[74vh] lg:h-[82vh] w-[100%] float-left">
          <div className="h-[34vh] sm:mt-[8vh] lg:h-[58vh] flex items-center justify-center">
            <img
              className="w-[100%] lg:w-[60%]"
              src={Error404}
              alt="Chipt_Logo"
            />
          </div>
          <div className="h-[20vh] text-center">
            <p className="font-medium text-2xl">Look like you're lost</p>
            <p className="text-sm py-1">
              the page you are looking for not available!
            </p>
            <Link to="/">
              <button className="bg-primaryBlue-btn1 text-text-color-light mt-2 py-1 px-4 font-medium rounded">
                Home Page
              </button>
            </Link>
          </div>
        </div>

        {/* bottom footer */}
        <div className="h-[8vh] sm:h-[6vh] w-[80%] mx-[10%] float-left">
          <div className="h-[4vh] sm:h-[3vh] flex items-center justify-center">
            <p className="flex text-xs">
              2024&nbsp;
              <img className="w-10" src={ChiptLogo} alt="Chipt_Logo" />
              &nbsp;All Rights Reserved
            </p>
          </div>
          <div className="h-[4vh] sm:h-[3vh] flex items-center justify-center">
            <Link to="/signin">
              <p className="text-xs font-medium underline decoration-1">
                Term and Condition
              </p>
            </Link>
            <p className="text-xs font-medium">&nbsp;and&nbsp;</p>
            <Link to="/signin">
              <p className="text-xs font-medium underline decoration-1">
                Privacy Policy
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
