import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ChiptAddressUpdate from "./Chipt_Address_Update";
import ChiptBasicDetailsUpdate from "./Chipt_Basic_Data_Update";
import ProfileUpdate from "./Chipt_Profile_Account_Image_Update";

const Chipt_Profile_Account_Update = () => {
  const [enableField, setEnableField] = useState(false);

  // const handleEnableEditField1 = (e) => {
  //   e.preventDefault();
  //   setEnableField(false);
  // };

  // // const handleEnableEditField2 = (e) => {
  // //   e.preventDefault();
  // //   setEnableField(true);
  // // };

  useEffect(() => {}, [enableField]);

  return (
    <>
      <div className="w-full  mx-auto px-8  h-full ">
        {/* Sub Container start */}
        <div className="w-[100%] h-full flex flex-col justify-center items-center relative">
          {/* Content Start */}
          <div className="w-[100%] h-auto ">
            <div className="w-[100%] h-auto  ">
              <ProfileUpdate />
            </div>

            {/* Update Option */}
            {/* <div className="w-[100%] h-[3rem] flex justify-center mt-2">
              <button
                // onClick={handleEnableEditField1}
                className={`w-[49.5%] mr-[0.5%] h-[2.5rem] float-left bg-primaryNavy hover:bg-primaryNavyHover text-lg ${
                  enableField ? "text-white" : "text-gold"
                } hover:text-gold font-bold rounded-lg hover:shadow-lg`}
              >
                Basic
              </button>
           
            </div> */}

            {/* Profile Imformation Update*/}
            <div className="w-[100%] h-auto float-left">
              {enableField ? (
                <ChiptAddressUpdate />
              ) : (
                <ChiptBasicDetailsUpdate />
              )}
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <title>CHIPT | PROFILE UPDATE</title>
      </Helmet>
    </>
  );
};

export default Chipt_Profile_Account_Update;
